class DataUrl {
	private dataUrl: string | null;

	constructor(dataUrl: string | null = null) {
		this.dataUrl = dataUrl;
	}

	toString(): string {
		return this.dataUrl!;
	}
}

export default DataUrl;
