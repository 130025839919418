export default function  getImageSize(source: string | Blob | MOxieFile) : Promise<{ width: number, height: number }>{
	const img = new Image;

	// If the image is a MOxieFile, first check if we've already set the image size
	if (typeof source.imageSize !== 'undefined') {
		return Promise.resolve({
			width: source.imageSize.width,
			height: source.imageSize.height,
		});
	}

	return new Promise((resolve) => {
		img.onload = function() {
			// If the image is a MOxieFile, save the image size
			if (typeof source.getNative === 'function') {
				source.imageSize = {
					width: img.width,
					height: img.height,
				};
			}

			resolve({
				width: img.width,
				height: img.height,
			})
		};

		let src;

		if (typeof source.getNative === 'function') {
			src = URL.createObjectURL(source.getNative());
		} else if (typeof source !== 'string') {
			src = URL.createObjectURL(source);
		} else if ( typeof source === 'string' ) {
			src = source;
		} else {
			throw new Error('Cannot get image size, unknown source type');
		};

		img.src = src;
	});
}
